import React, {useContext} from "react";
import { withRouter } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Logo from "../../../assets/of-original.jpg";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";


const NavBar = (props) => {
  const auth = useContext(AuthContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event) => {
    const btnId = event.currentTarget.getAttribute('data-key')
    if (btnId === "Admin"){

    }
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    setAnchorElUser(null);
    auth.logout();
  };

  return (
    <AppBar sx={{ backgroundColor: "white", width: "100%" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: 60,
                width: 135,
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
              alt="Odd Fellow"
              src={Logo}
            />
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "black" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Alla bokningar
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to="/bookings/user"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  Mina bokningar
                </Link>
              </MenuItem>

              {auth.isAdmin && (
                <MenuItem onClick={handleCloseNavMenu}>
                  <Link
                    to="/admin"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    Admin
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box
            sx={{
              marginRight: 5,
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
            }}
          >
            <Button
              component={Link}
              to="/"
              sx={{ my: 2, color: "black", display: "block", marginLeft: 5 }}
            >
              Alla bokningar
            </Button>
            <Button
              component={Link}
              to="/bookings/user"
              sx={{ my: 2, color: "black", display: "block", marginLeft: 5 }}
            >
              Mina bokningar
            </Button>
            {auth.isAdmin && (
              <Button
                component={Link}
                to="/admin"
                sx={{ my: 2, color: "black", display: "block", marginLeft: 5 }}
              >
                Admin
              </Button>
            )}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Visa info">
              <Button sx={{ m: 2 }} variant="contained">
                <IconButton
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, color: "#fff" }}
                >
                  {auth.userOrg}
                </IconButton>
              </Button>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem disabled={true}>
                <Typography textAlign="center">
                  Epost. {auth.userEmail}
                </Typography>
              </MenuItem>
              <MenuItem disabled={true}>
                <Typography textAlign="center">Inst. {auth.userOrg}</Typography>
              </MenuItem>
              <MenuItem disabled={true}>
                <Typography textAlign="center">
                  Name: {auth.userName}
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogOut}>
                <Typography textAlign="center">Logga ut</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default withRouter(NavBar);
