import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { svSE } from "@mui/material/locale";
import { SnackbarProvider } from "notistack";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#e0041c" },
    },
  },
  svSE
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);
