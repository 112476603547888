import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import NavBar from "./shared/components/navigation/NavBar";
import { AuthContext } from "./shared/context/AuthContext";
import { useAuth } from "./shared/hooks/auth-hook";

const Bookings = React.lazy(() => import("./booking/pages/Bookings"));
const Admin = React.lazy(() => import("./admin/pages/Admin"));
const Auth = React.lazy(() => import("./user/pages/Auth"));
const NewBooking = React.lazy(() => import("./booking/pages/NewBooking"));
const EditBooking = React.lazy(() => import("./booking/pages/EditBooking"));
const UserBookings = React.lazy(() => import("./booking/pages/UserBookings"));
const NewUser = React.lazy(() => import("./user/pages/NewUser"));
const NewLocation = React.lazy(() => import("./location/pages/NewLocation"));
const EditUser = React.lazy(() => import("./user/pages/UserEdit"));
const LocationEdit = React.lazy(() => import("./location/pages/LocationEdit"));

const App = () => {
  const {
    userToken,
    isUserAdmin,
    userEmail,
    userId,
    userOrg,
    userName,
    login,
    logout,
  } = useAuth();
  let routes;
  if (userToken && isUserAdmin) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Bookings />
        </Route>
        <Route path="/users/new" exact>
          <NewUser />
        </Route>
        <Route path="/users/edit" exact>
          <EditUser />
        </Route>
        <Route path="/locations/new" exact>
          <NewLocation />
        </Route>
        <Route path="/locations/edit" exact>
          <LocationEdit />
        </Route>
        <Route path="/admin" exact>
          <Admin />
        </Route>
        <Route path="/bookings/user" exact>
          <UserBookings />
        </Route>
        <Route path="/bookings/new" exact>
          <NewBooking />
        </Route>
        <Route path="/bookings/edit" exact>
          <EditBooking />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else if (!isUserAdmin && userToken) {
    routes = (
      <Switch>
        <Route path="/" exact>
          <Bookings />
        </Route>
        <Route path="/bookings/user" exact>
          <UserBookings />
        </Route>
        <Route path="/bookings/new" exact>
          <NewBooking />
        </Route>
        <Route path="/bookings/edit" exact>
          <EditBooking />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path="/login" exact>
          <Auth />
        </Route>
        <Redirect to="/login" />
      </Switch>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!userToken,
        token: userToken,
        isAdmin: isUserAdmin,
        userId: userId,
        userName: userName,
        userOrg: userOrg,
        userEmail: userEmail,
        login: login,
        logout: logout,
      }}
    >
      <Router>
        {userToken && <NavBar />}
        <main>
          <Suspense fallback={<div className="center"> Laddar...</div>}>{routes}</Suspense>
        </main>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
