import {useEffect, useState, useCallback} from "react";

let logoutTimer;

export const useAuth = () => {
    const [userToken, setToken] = useState();
    const [userId, setUserId] = useState();
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const [userName, setUserName] = useState();
    const [userOrg, setUserOrg] = useState();
    const [userEmail, setUserEmail] = useState();
    const [tokenExpDate, setTokenExpDate] = useState();
  
    const login = useCallback((resData) => {
      setToken(resData.token);
      setIsUserAdmin(resData.isAdmin);
      setUserId(resData.userId);
      setUserName(resData.name);
      setUserOrg(resData.organization);
      setUserEmail(resData.email);
      const expToken = resData.tokenExpirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
      setTokenExpDate(expToken);
      resData.tokenExpirationDate = expToken.toISOString();
      localStorage.setItem("userData", JSON.stringify(resData));
    }, []);
  
    const logout = useCallback(() => {
      setToken(null);
      setIsUserAdmin(null);
      setUserId(null);
      setUserName(null);
      setUserOrg(null);
      setUserEmail(null);
      localStorage.removeItem("userData");
      setTokenExpDate(null);
    }, []);
  
    useEffect(() => {
      if (userToken && tokenExpDate) {
        const remainingTime = tokenExpDate.getTime() - new Date().getTime();
        logoutTimer = setTimeout(logout, remainingTime);
      } else {
        clearTimeout(logoutTimer);
      }
    }, [userToken, logout, tokenExpDate]);
  
    useEffect(() => {
      const storedData = JSON.parse(localStorage.getItem("userData"));
      if (
        storedData &&
        storedData.token &&
        new Date(storedData.tokenExpirationDate) > new Date()
      ) {
        storedData.tokenExpirationDate = new Date(storedData.tokenExpirationDate);
        login(storedData);
      }
    }, [login]);

    return {userToken, isUserAdmin, userEmail, userId, userOrg, userName, login, logout};
}