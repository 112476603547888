import {createContext} from "react";

export const AuthContext = createContext({
    isLoggedIn: false,
    token: null,
    isAdmin: null,
    userId: null,
    userName: null,
    userOrg: null,
    userEmail: null,
    login: () => {},
    logout: () => {}
});
